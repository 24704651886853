
import { Code, Globe, Palette, Share2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { HighlighterItem, HighlightGroup, Particles } from "@/components/ui/highlighter";
import { Badge } from "@/components/ui/badge";

const ServicesSection = () => {
  const services = [
    {
      title: "Web Development",
      description: "Creating responsive, modern websites that drive engagement and results.",
      icon: <Globe className="h-8 w-8 text-primary stroke-1" />,
      span: true
    },
    {
      title: "Software Development",
      description: "Building robust applications that solve complex business challenges.",
      icon: <Code className="h-8 w-8 text-primary stroke-1" />,
      span: false
    },
    {
      title: "Branding",
      description: "Crafting unique brand identities that resonate with your audience.",
      icon: <Palette className="h-8 w-8 text-primary stroke-1" />,
      span: false
    },
    {
      title: "Social Media Marketing",
      description: "Engaging your audience across platforms to build lasting connections.",
      icon: <Share2 className="h-8 w-8 text-primary stroke-1" />,
      span: true
    }
  ];

  return (
    <section id="services" className="w-full py-20">
      <div className="max-w-[1300px] mx-auto px-4">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-center text-center space-y-4">
            <Badge variant="default">Our Solutions</Badge>
            <h2 className="text-4xl font-bold mb-6 animate-fade-in">Innovative Digital Strategies</h2>
            <p className="text-muted-foreground text-lg mb-12 max-w-2xl mx-auto animate-fade-in delay-100">
              We offer complete digital solutions designed to elevate your business in today's competitive landscape.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {services.map((service, index) => (
              <HighlightGroup key={index} className={cn("group h-full", 
                (index === 0 || index === 3) ? "lg:col-span-2" : "lg:col-span-1"
              )}>
                <div className="group/item h-full">
                  <HighlighterItem className="h-full">
                    <div className={cn(
                      "group relative h-full p-8 rounded-xl border bg-white/50 backdrop-blur-sm",
                      "transform transition-all duration-300 hover:scale-105",
                      "animate-fade-in-up min-h-[280px]",
                      `delay-${(index + 1) * 100}`
                    )}>
                      <Particles
                        className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                        quantity={30}
                        color="#44B649"
                        vy={-0.2}
                      />
                      <div className="flex flex-col justify-between h-full">
                        {service.icon}
                        <div className="flex flex-col mt-6">
                          <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                          <p className="text-muted-foreground">{service.description}</p>
                        </div>
                      </div>
                    </div>
                  </HighlighterItem>
                </div>
              </HighlightGroup>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
