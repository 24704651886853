import { Badge } from "@/components/ui/badge";
import { HighlighterItem, HighlightGroup, Particles } from "@/components/ui/highlighter";

const AboutSection = () => {
  return (
    <section id="about" className="w-full py-10">
      <div className="max-w-[1300px] mx-auto px-4">
        <div className="relative rounded-xl border border-[#8E9196]/20 bg-gradient-to-br from-white via-[#f7faf7] to-[#e8f5e9]/20 backdrop-blur-sm p-8">
          <div className="text-center mb-16">
            <Badge className="mb-4">About Us</Badge>
            <h2 className="text-4xl font-bold mb-6 animate-fade-in">Who We Are</h2>
            <p className="text-muted-foreground text-lg mb-12 max-w-2xl mx-auto animate-fade-in delay-100">
              Discover our journey in creating innovative digital solutions
            </p>
          </div>

          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <HighlightGroup className="group h-full">
              <div className="group/item h-full">
                <HighlighterItem className="h-full">
                  <div className="group relative h-full p-8 rounded-xl border bg-white/50 backdrop-blur-sm animate-fade-in-up delay-100">
                    <Particles
                      className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                      quantity={30}
                      color="#44B649"
                      vy={-0.2}
                    />
                    <div className="relative z-20 text-center">
                      <h3 className="text-2xl font-bold mb-4">Our Vision</h3>
                      <p className="text-muted-foreground">
                        To be the leading force in digital innovation, creating solutions that transform businesses and enhance user experiences.
                      </p>
                    </div>
                  </div>
                </HighlighterItem>
              </div>
            </HighlightGroup>

            <HighlightGroup className="group h-full">
              <div className="group/item h-full">
                <HighlighterItem className="h-full">
                  <div className="group relative h-full p-8 rounded-xl border bg-white/50 backdrop-blur-sm animate-fade-in-up delay-200">
                    <Particles
                      className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                      quantity={30}
                      color="#44B649"
                      vy={-0.2}
                    />
                    <div className="relative z-20 text-center">
                      <h3 className="text-2xl font-bold mb-4">Our Mission</h3>
                      <p className="text-muted-foreground">
                        To deliver exceptional digital solutions that empower businesses to thrive in the modern digital landscape.
                      </p>
                    </div>
                  </div>
                </HighlighterItem>
              </div>
            </HighlightGroup>

            <HighlightGroup className="group h-full">
              <div className="group/item h-full">
                <HighlighterItem className="h-full">
                  <div className="group relative h-full p-8 rounded-xl border bg-white/50 backdrop-blur-sm animate-fade-in-up delay-300">
                    <Particles
                      className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                      quantity={30}
                      color="#44B649"
                      vy={-0.2}
                    />
                    <div className="relative z-20 text-center">
                      <h3 className="text-2xl font-bold mb-4">Our Values</h3>
                      <p className="text-muted-foreground">
                        Innovation, excellence, and client satisfaction are at the core of everything we do.
                      </p>
                    </div>
                  </div>
                </HighlighterItem>
              </div>
            </HighlightGroup>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
