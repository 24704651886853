import { useState } from "react";
import { Mail, MapPin, Share2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { cn } from "@/lib/utils";
import { HighlighterItem, HighlightGroup, Particles } from "@/components/ui/highlighter";
import { supabase } from "@/integrations/supabase/client";

interface ContactCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  delay: string;
  children?: React.ReactNode;
}

const ContactCard = ({ title, description, icon, delay, children }: ContactCardProps) => (
  <HighlightGroup className="group h-full">
    <div className="group/item h-full">
      <HighlighterItem className="h-full">
        <div className={cn(
          "group relative h-full p-8 rounded-xl border bg-white/50 backdrop-blur-sm",
          "transform transition-all duration-300 hover:scale-105",
          "animate-fade-in-up",
          delay
        )}>
          <Particles
            className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
            quantity={30}
            color="#44B649"
            vy={-0.2}
          />
          <div className="relative z-20">
            <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4 group-hover:bg-primary/20 transition-colors">
              {icon}
            </div>
            <h3 className="text-2xl font-bold mb-4">{title}</h3>
            <p className="text-muted-foreground mb-4">{description}</p>
            {children}
          </div>
        </div>
      </HighlighterItem>
    </div>
  </HighlightGroup>
);

const ContactSection = () => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    privacy: false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.privacy) {
      toast({
        title: "Please agree to the privacy policy",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // First, save the contact submission
      const { error: submissionError } = await supabase
        .from('contact_submissions')
        .insert([{
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message
        }]);

      if (submissionError) throw submissionError;

      // Then call the email function
      const { error: emailError } = await supabase.functions.invoke('send-contact-email');
      
      if (emailError) throw emailError;

      toast({
        title: "Message sent successfully!",
        description: "We'll get back to you soon.",
      });
      setFormData({ name: "", email: "", phone: "", message: "", privacy: false });
    } catch (error: any) {
      console.error('Form submission error:', error);
      toast({
        title: "Error sending message",
        description: error.message || "Please try again later or contact us directly at info@synimi.com",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="w-full py-10 bg-white">
      <div className="max-w-[1300px] mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center mb-16 animate-fade-in">Interested in our services</h2>

        <div className="grid md:grid-cols-2 gap-8 items-stretch">
          {/* Contact Form */}
          <div className="bg-white p-8 rounded-xl border backdrop-blur-sm animate-fade-in-up delay-200 h-full flex items-center">
            <div className="w-full">
              <h3 className="text-2xl font-bold mb-12">Get in touch with us</h3>
              <form onSubmit={handleSubmit} className="space-y-8">
                <div className="inputForm">
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="input"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                    disabled={isSubmitting}
                  />
                </div>

                <div className="inputForm">
                  <input
                    type="email"
                    placeholder="Email"
                    className="input"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                    disabled={isSubmitting}
                  />
                </div>

                <div className="inputForm">
                  <input
                    type="tel"
                    placeholder="Phone"
                    className="input"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    required
                    disabled={isSubmitting}
                  />
                </div>

                <div className="inputForm h-32">
                  <textarea
                    placeholder="Message..."
                    className="input resize-none"
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    required
                    disabled={isSubmitting}
                  />
                </div>

                <div className="flex items-center gap-2 mt-8">
                  <input
                    type="checkbox"
                    id="privacy"
                    checked={formData.privacy}
                    onChange={(e) => setFormData({ ...formData, privacy: e.target.checked })}
                    className="rounded border-gray-300"
                    disabled={isSubmitting}
                  />
                  <label htmlFor="privacy" className="text-sm text-gray-600">
                    You agree to our friendly <a href="#" className="text-primary hover:underline">privacy policy</a>.
                  </label>
                </div>

                <button 
                  type="submit" 
                  className={cn(
                    "w-full bg-primary text-white py-4 rounded-lg hover:bg-primary/90 transition-colors mt-8",
                    isSubmitting && "opacity-50 cursor-not-allowed"
                  )}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Send message"}
                </button>
              </form>
            </div>
          </div>

          {/* Contact Cards */}
          <div className="grid gap-6">
            <ContactCard
              title="Email"
              description="General Support & Feedback"
              icon={<Mail className="h-6 w-6 text-primary" />}
              delay="delay-300"
            >
              <a href="mailto:info@synimi.com" className="text-primary hover:underline">
                info@synimi.com
              </a>
            </ContactCard>

            <ContactCard
              title="Location"
              description="Come and Say Hello!"
              icon={<MapPin className="h-6 w-6 text-primary" />}
              delay="delay-400"
            >
              <div className="space-y-1">
                <p>Kosovo</p>
                <p>Germany</p>
              </div>
            </ContactCard>

            <ContactCard
              title="Social Media"
              description="Connect with us"
              icon={<Share2 className="h-6 w-6 text-primary" />}
              delay="delay-500"
            >
              <div className="flex gap-4">
                <a href="#" className="text-primary hover:text-primary/80 transition-colors">
                  Instagram
                </a>
                <a href="#" className="text-primary hover:text-primary/80 transition-colors">
                  Facebook
                </a>
              </div>
            </ContactCard>
          </div>
        </div>

        {/* Highlighted Section */}
        <div className="mt-16">
          <HighlightGroup className="group h-full">
            <div className="group/item h-full">
              <HighlighterItem className="rounded-3xl p-6">
                <div className="relative z-20 h-full overflow-hidden rounded-3xl border border-slate-200 bg-white">
                  <Particles
                    className="absolute inset-0 -z-10 opacity-10 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-100"
                    quantity={200}
                    color="#44B649"
                    vy={-0.2}
                  />
                  <div className="flex justify-center">
                    <div className="flex h-full flex-col justify-center gap-10 p-4 md:h-[300px] md:flex-row">
                      <div className="relative mx-auto h-[270px] w-[300px] md:h-[270px] md:w-[300px]">
                        <div className="absolute left-1/2 top-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2">
                          <Mail className="h-6 w-6" />
                        </div>
                        <div className="absolute bottom-12 left-14 rounded-3xl border border-slate-400 bg-slate-200 px-2 py-1.5 text-xs opacity-50">
                          UI-UX
                        </div>
                        <div className="absolute left-2 top-20 rounded-3xl border border-slate-400 bg-slate-200 px-2 py-1.5 text-xs opacity-50">
                          Graphic Design
                        </div>
                        <div className="absolute bottom-20 right-1 rounded-3xl border border-slate-400 bg-slate-200 px-2 py-1.5 text-xs opacity-50">
                          Web Application
                        </div>
                        <div className="absolute right-12 top-10 rounded-3xl border border-slate-400 bg-slate-200 px-2 py-1.5 text-xs opacity-50">
                          Branding
                        </div>
                      </div>

                      <div className="-mt-20 flex h-full flex-col justify-center p-2 md:-mt-4 md:ml-10 md:w-[400px]">
                        <div className="flex flex-col items-center">
                          <h3 className="mt-6 pb-1 font-bold">
                            <span className="text-2xl md:text-4xl">
                              Any questions about our services?
                            </span>
                          </h3>
                        </div>
                        <p className="mb-4 text-center text-slate-400">
                          Feel free to reach out to us!
                        </p>
                        <div className="flex justify-center gap-2">
                          <a
                            href="mailto:info@synimi.com"
                            className="rounded-md bg-primary px-4 py-2 text-white hover:bg-primary/90 transition-colors"
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </HighlighterItem>
            </div>
          </HighlightGroup>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
