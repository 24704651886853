
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Badge } from "@/components/ui/badge";
import AboutSection from "@/components/AboutSection";
import { HighlighterItem, HighlightGroup, Particles } from "@/components/ui/highlighter";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Synimi - Leading Digital Agency in Kosovo & Albania</title>
        <meta name="description" content="Synimi është agjenci digjitale profesionale në Kosovë dhe Shqipëri. Ekspertë në zhvillimin e uebfaqeve, marketing digjital dhe branding në Prishtinë, Prizren, Pejë dhe qytete tjera." />
        <meta name="keywords" content="digital agency kosovo, agjenci digjitale shqiperi, web development company, kompani zhvillimi uebfaqesh, branding agency pristina" />
      </Helmet>

      <main className="min-h-screen">
        <Navbar />
        
        <div className="pt-24 pb-16 bg-gradient-to-b from-white to-gray-50">
          <div className="max-w-[1300px] mx-auto px-4">
            <div className="text-center space-y-4">
              <Badge variant="default">About Us</Badge>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Your Digital Success Partner</h1>
              <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
                Leading digital innovation across Kosovo and Albania with cutting-edge web solutions and creative design.
              </p>
            </div>
          </div>
        </div>

        <AboutSection />

        <section className="py-20">
          <div className="max-w-[1300px] mx-auto px-4">
            <HighlightGroup className="group">
              <div className="group/item">
                <HighlighterItem>
                  <div className="relative p-8 rounded-xl border bg-white/50 backdrop-blur-sm">
                    <Particles
                      className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                      quantity={30}
                      color="#44B649"
                      vy={-0.2}
                    />
                    <div className="grid md:grid-cols-2 gap-8">
                      <div>
                        <h2 className="text-3xl font-bold mb-4">Our Experience</h2>
                        <p className="text-muted-foreground mb-6">
                          With years of experience in digital solutions, we've helped numerous businesses across Kosovo and Albania establish their online presence.
                        </p>
                        <ul className="space-y-2">
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            100+ Successful Projects
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Experienced Team
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Latest Technologies
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h2 className="text-3xl font-bold mb-4">Our Approach</h2>
                        <p className="text-muted-foreground mb-6">
                          We combine creativity with technical expertise to deliver solutions that drive results for your business.
                        </p>
                        <ul className="space-y-2">
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Client-Focused Solutions
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Innovative Design
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Continuous Support
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </HighlighterItem>
              </div>
            </HighlightGroup>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default About;
