
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Badge } from "@/components/ui/badge";
import ContactSection from "@/components/ContactSection";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Synimi - Web Development & Digital Services in Kosovo</title>
        <meta name="description" content="Kontaktoni Synimi për zhvillimin e uebfaqeve profesionale, website development dhe shërbime digjitale në Kosovë dhe Shqipëri. Na gjeni në Prishtinë, Prizren, Pejë dhe qytete tjera." />
        <meta name="keywords" content="contact synimi, kontakto synimi, web development contact, uebfaqe kontakt, website development kosovo contact" />
      </Helmet>

      <main className="min-h-screen">
        <Navbar />
        
        <div className="pt-24 pb-16 bg-gradient-to-b from-white to-gray-50">
          <div className="max-w-[1300px] mx-auto px-4">
            <div className="text-center space-y-4">
              <Badge variant="default">Contact Us</Badge>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Get In Touch</h1>
              <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
                Let's discuss how we can help transform your digital presence
              </p>
            </div>
          </div>
        </div>

        <ContactSection />

        <Footer />
      </main>
    </>
  );
};

export default Contact;
