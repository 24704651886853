
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Badge } from "@/components/ui/badge";
import ServicesSection from "@/components/ServicesSection";
import { HighlighterItem, HighlightGroup, Particles } from "@/components/ui/highlighter";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Professional Web Development & Digital Services - Synimi</title>
        <meta name="description" content="Zhvillimi profesional i uebfaqeve, website development, web design në Kosovë dhe Shqipëri. Shërbime në Prishtinë, Prizren, Pejë, Gjakovë, Ferizaj, Mitrovicë, Gjilan, Vlorë, Durrës, Elbasan, Tiranë, Shkodër. Specializuar në WordPress, React, eCommerce." />
        <meta name="keywords" content="web development kosovo, website design albania, uebfaqe profesionale, webfaqe kosove, web development prishtina, dizajn uebfaqesh, ecommerce solutions, online shop development" />
      </Helmet>

      <main className="min-h-screen">
        <Navbar />
        
        <div className="pt-24 pb-16 bg-gradient-to-b from-white to-gray-50">
          <div className="max-w-[1300px] mx-auto px-4">
            <div className="text-center space-y-4">
              <Badge variant="default">Our Services</Badge>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Comprehensive Digital Solutions</h1>
              <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
                Transform your business with our professional web development, branding, and digital marketing services.
              </p>
            </div>
          </div>
        </div>

        <ServicesSection />

        <section className="py-20">
          <div className="max-w-[1300px] mx-auto px-4">
            <HighlightGroup className="group">
              <div className="group/item">
                <HighlighterItem>
                  <div className="relative p-8 rounded-xl border bg-white/50 backdrop-blur-sm">
                    <Particles
                      className="absolute inset-0 -z-10 opacity-0 transition-opacity duration-1000 ease-in-out group-hover/item:opacity-20"
                      quantity={30}
                      color="#44B649"
                      vy={-0.2}
                    />
                    <div className="grid md:grid-cols-2 gap-8">
                      <div>
                        <h2 className="text-3xl font-bold mb-4">WordPress Development</h2>
                        <p className="text-muted-foreground mb-6">
                          Expert WordPress development services tailored to your needs. We create custom themes, plugins, and fully optimized WordPress websites.
                        </p>
                        <ul className="space-y-2">
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Custom Theme Development
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Plugin Integration & Development
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            WooCommerce Solutions
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h2 className="text-3xl font-bold mb-4">Custom Web Applications</h2>
                        <p className="text-muted-foreground mb-6">
                          Build powerful web applications using modern technologies like React, Next.js, and Node.js.
                        </p>
                        <ul className="space-y-2">
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Full-Stack Development
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            API Integration
                          </li>
                          <li className="flex items-center gap-2">
                            <span className="w-2 h-2 bg-primary rounded-full"></span>
                            Real-time Applications
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </HighlighterItem>
              </div>
            </HighlightGroup>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Services;
