import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { ImagePlus, Pencil, Trash, X, Image } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface PortfolioItem {
  id: string;
  title: string;
  subtitle: string;
  featured_image: string;
  gallery: string[];
}

const Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [portfolioItems, setPortfolioItems] = useState<PortfolioItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingItem, setEditingItem] = useState<PortfolioItem | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [featuredImage, setFeaturedImage] = useState<File | null>(null);
  const [galleryImages, setGalleryImages] = useState<File[]>([]);
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [currentFavicon, setCurrentFavicon] = useState("");
  const [isFaviconDialogOpen, setIsFaviconDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("portfolio");
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setIsAuthenticated(true);
        fetchPortfolioItems();
        fetchCurrentFavicon();
      }
    };
    checkAuth();
  }, []);

  const fetchCurrentFavicon = async () => {
    try {
      // Get favicon from special portfolio item
      const { data: faviconData, error: faviconError } = await supabase
        .from('portfolios')
        .select('featured_image')
        .eq('title', 'site_favicon')
        .maybeSingle();

      if (!faviconError && faviconData?.featured_image) {
        setCurrentFavicon(faviconData.featured_image);
        return;
      }

      // Fallback: get from current document
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        setCurrentFavicon(favicon.getAttribute('href') || "");
      }
    } catch (error) {
      console.error("Error fetching favicon:", error);
    }
  };

  const fetchPortfolioItems = async () => {
    try {
      const { data, error } = await supabase
        .from("portfolios")
        .select()
        .neq('title', 'site_favicon') // Don't include the favicon in portfolio items
        .order("created_at", { ascending: false });

      if (error) throw error;
      if (data) setPortfolioItems(data);
    } catch (error) {
      console.error("Error fetching portfolio items:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load portfolio items",
      });
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        console.error("Login error:", signInError);
        toast({
          variant: "destructive",
          title: "Invalid credentials",
          description: "Please check your email and password",
        });
        return;
      }

      if (data.user) {
        setIsAuthenticated(true);
        fetchPortfolioItems();
        fetchCurrentFavicon();
        toast({
          title: "Logged in successfully",
          description: "Welcome to the admin panel",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "An error occurred while logging in",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle("");
    setSubtitle("");
    setFeaturedImage(null);
    setGalleryImages([]);
    setEditingItem(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!title || !subtitle || (!editingItem && !featuredImage)) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please fill in all required fields (title, subtitle, and featured image)",
        });
        setIsLoading(false);
        return;
      }

      let featuredImageUrl = editingItem?.featured_image;
      let galleryUrls = editingItem?.gallery || [];

      if (featuredImage) {
        const featuredImageName = `${crypto.randomUUID()}-${featuredImage.name}`;
        const { error: uploadError } = await supabase.storage
          .from("portfolio")
          .upload(featuredImageName, featuredImage);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from("portfolio")
          .getPublicUrl(featuredImageName);

        featuredImageUrl = publicUrl;
      }

      if (galleryImages.length > 0) {
        const newGalleryUrls = await Promise.all(
          galleryImages.map(async (file) => {
            const fileName = `${crypto.randomUUID()}-${file.name}`;
            const { error } = await supabase.storage
              .from("portfolio")
              .upload(fileName, file);

            if (error) throw error;

            const { data: { publicUrl } } = supabase.storage
              .from("portfolio")
              .getPublicUrl(fileName);

            return publicUrl;
          })
        );

        galleryUrls = editingItem ? [...editingItem.gallery, ...newGalleryUrls] : newGalleryUrls;
      }

      const portfolioData = {
        title,
        subtitle,
        featured_image: featuredImageUrl,
        gallery: galleryUrls,
      };

      console.log("Sending portfolio data:", portfolioData);

      if (editingItem) {
        const { error: updateError } = await supabase
          .from("portfolios")
          .update(portfolioData)
          .eq("id", editingItem.id);

        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from("portfolios")
          .insert(portfolioData);

        if (insertError) throw insertError;
      }

      toast({
        title: "Success",
        description: `Portfolio item ${editingItem ? "updated" : "added"} successfully`,
      });

      resetForm();
      setIsDialogOpen(false);
      fetchPortfolioItems();
    } catch (error) {
      console.error("Error:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: `An error occurred while ${editingItem ? "updating" : "saving"} the portfolio item`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFaviconUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!faviconFile) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select a favicon image to upload",
        });
        setIsLoading(false);
        return;
      }

      // Check file type
      const fileType = faviconFile.type;
      if (!fileType.match(/image\/(png|jpeg|ico|svg\+xml)/)) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select a PNG, JPEG, ICO, or SVG file",
        });
        setIsLoading(false);
        return;
      }

      // Check file size (max 1MB)
      if (faviconFile.size > 1024 * 1024) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "File size should be less than 1MB",
        });
        setIsLoading(false);
        return;
      }

      // Create a unique file name to ensure caching doesn't prevent updates
      const timestamp = new Date().getTime();
      const fileExtension = faviconFile.name.split('.').pop() || 'png';
      const faviconFileName = `favicon-${timestamp}.${fileExtension}`;
      
      console.log("Uploading favicon:", faviconFileName, "with type:", fileType);
      
      // Upload to 'portfolio' bucket which we know exists
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('portfolio')
        .upload(faviconFileName, faviconFile, { 
          upsert: true,
          contentType: fileType
        });

      if (uploadError) {
        console.error("Upload error:", uploadError);
        throw new Error(`Upload failed: ${uploadError.message}`);
      }

      console.log("Upload successful:", uploadData);

      // Get the public URL
      const { data: { publicUrl } } = supabase.storage
        .from('portfolio')
        .getPublicUrl(faviconFileName);

      console.log("Public URL:", publicUrl);

      // Store the favicon URL in portfolios table for persistence across all pages
      await saveFaviconToDatabase(publicUrl);
      
      // Update all favicon links in the current document
      updateFaviconInDocument(publicUrl);

      setCurrentFavicon(publicUrl);
      
      toast({
        title: "Success",
        description: "Favicon updated successfully. You may need to clear your browser cache to see the changes immediately.",
      });

      setFaviconFile(null);
      setIsFaviconDialogOpen(false);
    } catch (error) {
      console.error("Error in handleFaviconUpload:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "An error occurred while updating the favicon",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateFaviconInDocument = (publicUrl: string) => {
    // Update all favicon links in the document
    const faviconLinks = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"], link[rel="shortcut icon"]');
    faviconLinks.forEach(link => {
      link.setAttribute('href', publicUrl);
    });

    // If no favicon links exist, create one
    if (faviconLinks.length === 0) {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.href = publicUrl;
      document.head.appendChild(newFavicon);
    }
  };

  const saveFaviconToDatabase = async (faviconUrl: string) => {
    try {
      // Check if favicon entry exists
      const { data: existingFavicon, error: checkError } = await supabase
        .from('portfolios')
        .select('id')
        .eq('title', 'site_favicon')
        .maybeSingle();

      if (checkError) {
        console.error("Error checking for existing favicon:", checkError);
      }

      if (existingFavicon?.id) {
        // Update existing record
        return await supabase
          .from('portfolios')
          .update({ 
            featured_image: faviconUrl,
            subtitle: 'System favicon'
          })
          .eq('id', existingFavicon.id);
      } else {
        // Create new record
        return await supabase
          .from('portfolios')
          .insert({
            title: 'site_favicon',
            subtitle: 'System favicon',
            featured_image: faviconUrl
          });
      }
    } catch (error) {
      console.error("Error saving favicon to database:", error);
      throw error;
    }
  };

  const handleEdit = (item: PortfolioItem) => {
    setEditingItem(item);
    setTitle(item.title);
    setSubtitle(item.subtitle);
    setIsDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const { error } = await supabase
          .from("portfolios")
          .delete()
          .eq("id", id);

        if (error) throw error;

        toast({
          title: "Success",
          description: "Portfolio item deleted successfully",
        });

        fetchPortfolioItems();
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to delete portfolio item",
        });
      }
    }
  };

  const handleAddNew = () => {
    resetForm();
    setIsDialogOpen(true);
  };

  const openFaviconDialog = () => {
    setFaviconFile(null);
    setIsFaviconDialogOpen(true);
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <Card className="w-[400px]">
          <CardHeader>
            <CardTitle>Admin Login</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleLogin} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? "Loading..." : "Login"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Admin Panel</h1>
          <div className="space-x-4">
            <Button onClick={() => navigate("/portfolio")}>View Portfolio</Button>
            <Button variant="outline" onClick={openFaviconDialog}>
              <Image className="w-4 h-4 mr-2" />
              Change Favicon
            </Button>
          </div>
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="mb-8">
          <TabsList>
            <TabsTrigger value="portfolio">Portfolio</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
          </TabsList>
          
          <TabsContent value="portfolio" className="pt-6">
            <div className="flex justify-end mb-6">
              <Button onClick={handleAddNew}>
                <ImagePlus className="w-4 h-4 mr-2" />
                Add New Item
              </Button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {portfolioItems.map((item) => (
                <Card key={item.id} className="overflow-hidden">
                  <CardContent className="p-0">
                    <div className="relative aspect-square">
                      <img
                        src={item.featured_image}
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black/60 opacity-0 hover:opacity-100 transition-opacity duration-200">
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                          <h3 className="text-white text-lg font-semibold mb-2">{item.title}</h3>
                          <p className="text-gray-200 text-sm mb-4">{item.subtitle}</p>
                          <div className="flex space-x-2">
                            <Button
                              size="sm"
                              variant="secondary"
                              onClick={() => handleEdit(item)}
                            >
                              <Pencil className="w-4 h-4 mr-2" />
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              variant="destructive"
                              onClick={() => handleDelete(item.id)}
                            >
                              <Trash className="w-4 h-4 mr-2" />
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>
          
          <TabsContent value="settings" className="pt-6">
            <Card>
              <CardHeader>
                <CardTitle>Website Settings</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-medium mb-4">Favicon</h3>
                    <div className="flex items-center space-x-4">
                      <div className="w-16 h-16 border rounded flex items-center justify-center overflow-hidden bg-white">
                        {currentFavicon ? (
                          <img
                            src={currentFavicon}
                            alt="Current Favicon"
                            className="max-w-full max-h-full"
                          />
                        ) : (
                          <span className="text-gray-400">No favicon</span>
                        )}
                      </div>
                      <Button onClick={openFaviconDialog}>Change Favicon</Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>{editingItem ? "Edit Portfolio Item" : "Add New Portfolio Item"}</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="subtitle">Subtitle</Label>
                <Input
                  id="subtitle"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="featured-image">
                  Featured Image {editingItem && "(Leave empty to keep current image)"}
                </Label>
                <Input
                  id="featured-image"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setFeaturedImage(e.target.files?.[0] || null)}
                  required={!editingItem}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="gallery">
                  Gallery Images {editingItem && "(Select multiple to add to existing gallery)"}
                </Label>
                <Input
                  id="gallery"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => setGalleryImages(Array.from(e.target.files || []))}
                />
                {editingItem && editingItem.gallery.length > 0 && (
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    {editingItem.gallery.map((image, index) => (
                      <div key={index} className="relative cursor-pointer group" onClick={() => setSelectedImage(image)}>
                        <img
                          src={image}
                          alt={`Gallery ${index + 1}`}
                          className="w-full aspect-square object-cover rounded-lg"
                        />
                        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center">
                          <span className="text-white text-sm">Click to view</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-4">
                <Button type="button" variant="outline" onClick={() => setIsDialogOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? "Saving..." : editingItem ? "Update" : "Add"}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog open={isFaviconDialogOpen} onOpenChange={setIsFaviconDialogOpen}>
          <DialogContent className="max-w-md">
            <DialogHeader>
              <DialogTitle>Update Website Favicon</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleFaviconUpload} className="space-y-6">
              <div className="space-y-4">
                <div className="flex justify-center">
                  <div className="w-24 h-24 border rounded flex items-center justify-center overflow-hidden bg-white">
                    {faviconFile ? (
                      <img
                        src={URL.createObjectURL(faviconFile)}
                        alt="New Favicon Preview"
                        className="max-w-full max-h-full"
                      />
                    ) : currentFavicon ? (
                      <img
                        src={currentFavicon}
                        alt="Current Favicon"
                        className="max-w-full max-h-full"
                      />
                    ) : (
                      <span className="text-gray-400">No favicon</span>
                    )}
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="favicon">Select New Favicon</Label>
                  <Input
                    id="favicon"
                    type="file"
                    accept="image/png,image/jpeg,image/ico,image/svg+xml"
                    onChange={(e) => setFaviconFile(e.target.files?.[0] || null)}
                    required
                  />
                  <p className="text-xs text-gray-500">
                    Recommended: Square PNG image (32x32 or 64x64 pixels). Max size: 1MB.
                  </p>
                </div>
              </div>
              <div className="flex justify-end space-x-4">
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setIsFaviconDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoading || !faviconFile}>
                  {isLoading ? "Uploading..." : "Update Favicon"}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
          <DialogContent className="max-w-4xl p-0">
            <div className="relative">
              <Button
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2 z-10"
                onClick={() => setSelectedImage(null)}
              >
                <X className="h-4 w-4" />
              </Button>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Gallery"
                  className="w-full h-auto max-h-[80vh] object-contain"
                />
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default Admin;
