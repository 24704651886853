
import { ArrowRight } from "lucide-react";
import { ShimmerButton } from "@/components/ui/shimmer-button";
import { memo } from "react";

const Hero = memo(() => {
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center animated-gradient gradient-fade-bottom pt-8">
      <div className="max-w-[1300px] w-full mx-auto px-4 text-center relative">
        <div className="absolute left-0 top-1/2 -translate-y-1/2 hidden lg:block">
          <img 
            src="/lovable-uploads/f4ff24ab-03c8-450e-81b1-1ead77ed694e.png"
            alt="Brand Eye"
            className="w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 animate-spin-slow"
            width={160}
            height={160}
            loading="eager"
            decoding="async"
            fetchPriority="high"
          />
        </div>

        <div className="absolute right-0 top-1/2 -translate-y-1/2 hidden lg:block">
          <img 
            src="/lovable-uploads/f4ff24ab-03c8-450e-81b1-1ead77ed694e.png"
            alt="Brand Eye"
            className="w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 animate-spin-slow"
            width={160}
            height={160}
            loading="eager"
            decoding="async"
            fetchPriority="high"
          />
        </div>

        <div className="space-y-4 animate-fade-in-up px-4 sm:px-6 md:px-8">
          <div className="block md:hidden">
            <h1 className="text-4xl font-bold mb-2 font-poppins">
              Transforming
            </h1>
            <h1 className="text-4xl font-bold mb-2 font-poppins">
              Ideas into
            </h1>
            <h1 className="text-4xl font-bold mb-6 font-poppins text-primary">
              Digital Reality
            </h1>
          </div>

          <div className="hidden md:block">
            <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold mb-2 font-poppins">
              Transforming Ideas into
            </h1>
            <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold mb-6 font-poppins text-primary">
              Digital Reality
            </h1>
          </div>
        </div>

        <div className="mt-8">
          <p className="text-base sm:text-lg md:text-xl text-muted-foreground max-w-2xl mx-auto mb-8 animate-fade-in-up delay-200 px-4">
            We specialize in Web Development, Software Development, and Branding solutions that help businesses thrive in the digital age.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 animate-fade-in-up delay-300 mb-12">
            <ShimmerButton
              className="w-full sm:w-auto min-w-[200px]"
              background="rgba(68, 182, 73, 1)"
              onClick={() => scrollToSection('contact')}
            >
              <span className="flex items-center">
                Get Started
                <ArrowRight className="ml-2 h-4 w-4" />
              </span>
            </ShimmerButton>
            <ShimmerButton
              className="w-full sm:w-auto min-w-[200px]"
              background="rgba(0, 0, 0, 0.8)"
              onClick={() => scrollToSection('services')}
            >
              Our Services
            </ShimmerButton>
          </div>
        </div>
      </div>
    </section>
  );
});

Hero.displayName = "Hero";

export default Hero;
