
import { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { useInView } from "react-intersection-observer";
import { useToast } from "@/components/ui/use-toast";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BackToTop from "@/components/BackToTop";

interface PortfolioItem {
  id: string;
  title: string;
  subtitle: string;
  featured_image: string;
  gallery: string[];
}

const Portfolio = () => {
  const [portfolios, setPortfolios] = useState<PortfolioItem[]>([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioItem | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { toast } = useToast();
  const { ref, inView } = useInView();

  const transformImageUrl = (url: string) => {
    if (!url) return '';
    
    // Remove synimi.com URL parts if present
    if (url.includes('synimi.com')) {
      url = url.split('/').pop() || '';
    }
    
    // If it's just a filename, prepend the full Supabase path
    if (!url.includes('supabase.co')) {
      url = `https://dvpdbrhubbcqnirsjbvo.supabase.co/storage/v1/render/image/public/portfolio/${url}`;
    }
    
    // Add optimization parameters if they don't exist
    if (!url.includes('?')) {
      url += '?width=800&format=webp&quality=80';
    }
    
    return url;
  };

  const fetchPortfolios = async () => {
    try {
      const from = (page - 1) * 12;
      const to = from + 11;

      const { data, error } = await supabase
        .from("portfolios")
        .select()
        .neq('title', 'site_favicon') // Exclude the favicon from portfolio items
        .order("created_at", { ascending: false })
        .range(from, to);

      if (error) throw error;

      if (data) {
        const transformedData = data.map(item => ({
          ...item,
          featured_image: transformImageUrl(item.featured_image),
          gallery: item.gallery.map(transformImageUrl)
        }));
        setPortfolios(prev => [...prev, ...transformedData]);
        setHasMore(data.length === 12);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load portfolio items",
      });
      console.error('Portfolio fetch error:', error);
    }
  };

  useEffect(() => {
    fetchPortfolios();
  }, [page]);

  useEffect(() => {
    if (inView && hasMore) {
      setPage(prev => prev + 1);
    }
  }, [inView, hasMore]);

  const handlePortfolioClick = (portfolio: PortfolioItem) => {
    setSelectedPortfolio(portfolio);
    setCurrentImageIndex(0);
  };

  const nextImage = () => {
    if (!selectedPortfolio) return;
    const maxIndex = selectedPortfolio.gallery.length - 1;
    setCurrentImageIndex(prev => prev === maxIndex ? 0 : prev + 1);
  };

  const previousImage = () => {
    if (!selectedPortfolio) return;
    const maxIndex = selectedPortfolio.gallery.length - 1;
    setCurrentImageIndex(prev => prev === 0 ? maxIndex : prev - 1);
  };

  const closeDialog = () => {
    setSelectedPortfolio(null);
    setCurrentImageIndex(0);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-grow animated-gradient gradient-fade-bottom">
        <div className="container py-32">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-4 animate-fade-in">Our Portfolio</h1>
          <p className="text-muted-foreground text-center mb-12 max-w-2xl mx-auto animate-fade-in">
            Discover our creative work and successful projects
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {portfolios.map((item) => (
              <Card
                key={item.id}
                className="overflow-hidden cursor-pointer group bg-white/80 backdrop-blur-sm hover:bg-white/90 transition-all duration-300"
                onClick={() => handlePortfolioClick(item)}
              >
                <CardContent className="p-0">
                  <div className="relative aspect-square">
                    <img
                      src={item.featured_image}
                      alt={item.title}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                      loading="lazy"
                      decoding="async"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <div className="absolute bottom-0 left-0 right-0 p-6 text-white transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                        <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                        <p className="text-sm text-gray-200">{item.subtitle}</p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
          
          {hasMore && (
            <div ref={ref} className="h-20 flex items-center justify-center">
              <div className="animate-pulse text-muted-foreground">Loading more...</div>
            </div>
          )}
        </div>
      </main>

      <Dialog open={!!selectedPortfolio} onOpenChange={closeDialog}>
        <DialogContent className="max-w-5xl p-0 bg-black/90">
          {selectedPortfolio && selectedPortfolio.gallery.length > 0 && (
            <div className="relative">
              <Button
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2 z-10 text-white hover:bg-white/20"
                onClick={closeDialog}
              >
                <X className="h-6 w-6" />
              </Button>
              
              <div className="relative">
                <img
                  src={selectedPortfolio.gallery[currentImageIndex]}
                  alt={`${selectedPortfolio.title} - ${currentImageIndex + 1}`}
                  className="w-full h-auto max-h-[80vh] object-contain"
                  loading="lazy"
                  decoding="async"
                />
                
                {selectedPortfolio.gallery.length > 1 && (
                  <>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute left-2 top-1/2 -translate-y-1/2 text-white hover:bg-white/20"
                      onClick={previousImage}
                    >
                      <ChevronLeft className="h-8 w-8" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-white hover:bg-white/20"
                      onClick={nextImage}
                    >
                      <ChevronRight className="h-8 w-8" />
                    </Button>
                  </>
                )}
                
                <div className="absolute bottom-4 left-0 right-0 text-center text-white">
                  <h3 className="text-xl font-semibold mb-2">{selectedPortfolio.title}</h3>
                  <p className="text-sm text-gray-200">{selectedPortfolio.subtitle}</p>
                  <p className="text-sm mt-2">
                    Image {currentImageIndex + 1} of {selectedPortfolio.gallery.length}
                  </p>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Footer />
      <BackToTop />
    </div>
  );
};

export default Portfolio;
