// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://dvpdbrhubbcqnirsjbvo.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR2cGRicmh1YmJjcW5pcnNqYnZvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzY5NzEyMDcsImV4cCI6MjA1MjU0NzIwN30.SxfbncAxeoViNhsKvXYCc-D_yaxw_XVj4iXH3DljrXA";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);