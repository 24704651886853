
import { useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';

const FaviconLoader = () => {
  useEffect(() => {
    const loadFavicon = async () => {
      try {
        // Try to get favicon from portfolios table with a special title
        const { data, error } = await supabase
          .from('portfolios')
          .select('featured_image')
          .eq('title', 'site_favicon')
          .maybeSingle();

        if (error) {
          console.error('Error loading favicon from database:', error);
          return;
        }

        if (data && data.featured_image) {
          // Update all favicon links in the document
          const faviconLinks = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"], link[rel="shortcut icon"]');
          faviconLinks.forEach(link => {
            link.setAttribute('href', data.featured_image);
          });
          
          // If no favicon links exist, create one
          if (faviconLinks.length === 0) {
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = data.featured_image;
            document.head.appendChild(newFavicon);
          }
        }
      } catch (err) {
        console.error('Error in FaviconLoader:', err);
      }
    };

    loadFavicon();
  }, []);

  return null;
};

export default FaviconLoader;
