
import { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList } from "@/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const NavItems = ({ isMobile = false }: { isMobile?: boolean }) => (
    <NavigationMenu>
      <NavigationMenuList className={`${isMobile ? 'flex-col items-start' : ''}`}>
        <NavigationMenuItem>
          <NavigationMenuLink 
            className={`px-4 py-2 hover:text-primary transition-colors block ${isMobile ? 'text-lg mb-4' : ''}`} 
            asChild
          >
            <Link to="/">Home</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink 
            className={`px-4 py-2 hover:text-primary transition-colors block ${isMobile ? 'text-lg mb-4' : ''}`} 
            asChild
          >
            <Link to="/services">Our Services</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink 
            className={`px-4 py-2 hover:text-primary transition-colors block ${isMobile ? 'text-lg mb-4' : ''}`} 
            asChild
          >
            <Link to="/about">About Us</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink 
            className={`px-4 py-2 hover:text-primary transition-colors block ${isMobile ? 'text-lg mb-4' : ''}`} 
            asChild
          >
            <Link to="/portfolio">Portfolio</Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        {isMobile && (
          <NavigationMenuItem>
            <NavigationMenuLink 
              className="px-4 py-2 hover:text-primary transition-colors block text-lg mb-4"
              asChild
            >
              <Link to="/contact">Contact Us</Link>
            </NavigationMenuLink>
          </NavigationMenuItem>
        )}
      </NavigationMenuList>
    </NavigationMenu>
  );

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'blur-header' : 'bg-transparent'}`}>
      <div className="max-w-[1300px] mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center gap-8">
          <Link to="/">
            <img src="/lovable-uploads/a9de5715-706a-4ae3-b516-3a88f3fc7ccb.png" alt="Synimi Logo" className="h-8" />
          </Link>
        </div>
        
        <div className="flex items-center gap-6">
          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <NavItems />
          </div>

          {/* Contact Us Button - Desktop Only */}
          <div className="hidden md:block">
            <Button 
              variant="outline"
              className="rounded-xl"
              asChild
            >
              <Link to="/contact">Contact Us</Link>
            </Button>
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <button className="p-2">
                  <Menu className="h-6 w-6" />
                </button>
              </SheetTrigger>
              <SheetContent 
                side="right" 
                className="w-[80%] sm:max-w-[80%] p-6 border-none bg-background/95 backdrop-blur-sm"
              >
                <nav className="flex flex-col">
                  <NavItems isMobile={true} />
                </nav>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
